if (module.hot) {
  module.hot.accept();
}

/**stylelint-disable */
// import external dependencies
import "jquery";
import "owl.carousel2";
import "bootstrap-rating-input";
import Masonry from "masonry-layout";

// Import everything from autoload
import "./autoload/_bootstrap.js";

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import aboutUs from "./routes/about";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
jQuery(document).ready(function ($) {
  // $('.products-ajax').submit()
  /**
   * Boot the owl carousel
   */
  $(".banner-carousel.owl-carousel").owlCarousel({
    loop: true,
    autoplay: true,
    autoplayTimeout: 7000,
    // autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1,
      },
    },
  });

  $(".front-page-product-items.owl-carousel").owlCarousel({
    loop: true,
    autoplay: true,
    dots: true,
    nav: false,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      767: {
        items: 3,
      },
      991: {
        items: 4,
      },
    },
  });

  if ($(".count-amount").length) {
    countSKSaanto(1);
  }

  $(".countNewValues").on("click", function (event) {
    event.preventDefault();
    countSKSaanto(0);

    if ($(window).width() < 767) {
      $("html, body").animate(
        {
          scrollTop: $(".recipe-ingredients").offset().top,
        },
        2000
      );
    }
  });

  function isInt(value) {
    var x;
    return isNaN(value) ? !1 : ((x = parseFloat(value)), (0 | x) === x);
  }

  function countSKSaanto(first) {
    var maara = $("#maaraInput").val();
    var annos = $("#kokoInput").val();
    var saanto = (maara * annos) / 1000;

    if (isInt(maara) && isInt(annos)) {
      if (first) {
        $(".countSaanto").attr("data-orig", saanto);
      }

      console.log("click");
      console.log("click223");

      // laske calcVal arvot kertoimella og saanto / uusi saanto
      var ogsaanto = parseFloat($(".countSaanto").attr("data-orig"));
      let yhteensa_saanto = 0;

      $(".calcVal").each(function () {
        var value = parseFloat($(this).attr("data-orig"));

        if (value) {
          // Move up to the parent <tr> and find the <td> with the unit
          var unit = $(this)
            .closest("tr")
            .find("td:nth-child(2)")
            .text()
            .trim();

          if (unit === "kg") {
            // Only perform the calculation if the unit is "kg"
            var nvalue = value * (saanto / ogsaanto);
            nvalue = Math.round(nvalue * 1000) / 1000; // Round to 3 decimals

            yhteensa_saanto += nvalue;

            // Update the text inside the <span> with the new value
            $(this).text(nvalue.toString().replace(".", ","));
          }
        }
      });

      saanto = Math.floor(yhteensa_saanto * 10) / 10;
      $(".countSaanto").html(saanto + " kg");
    } else {
      alert("Syötä kenttiin Annosmäärä ja Annoskoko vain kokonaislukuja.");
    }
  }

  $(".search-toggle").on("click", function (event) {
    event.preventDefault();
    $(".search-form-desktop").toggleClass("is-visible");
  });

  $(".share-this-toggle").on("click", function (event) {
    event.preventDefault();
    $(".share-container-buttons").slideToggle(400);
  });

  $(".copy-to-clipboard").on("click", function (event) {
    event.preventDefault();

    var elemID = "currentUrlInput";
    if ($(this).hasClass("is-multi")) {
      elemID = "currentUrlInput2";
    }
    copyToClipboard(elemID);

    $(".before-copy").fadeOut("400", function () {
      $(".after-copy").fadeIn(400);
    });
  });

  $(".news-teaser-toggle").on("click", function (event) {
    event.preventDefault();
    $(this).toggleClass("active").siblings(".news-teaser").slideToggle(400);
  });

  $("#form-releasesYear, #form-releasesCategory").change(function (event) {
    event.preventDefault();
    $(".releases-search form").submit();
  });

  $("#load-tuorestai-recipes").on("click", function (event) {
    event.preventDefault();

    $(".tuorestai-recipes .tuorestai-recipe:hidden:lt(8)").fadeIn("fast");

    if ($(".tuorestai-recipes .tuorestai-recipe:hidden").length <= 0) {
      $("#load-tuorestai-recipes").fadeOut("fast");
    }
  });

  $(".recipe-page .rating-holder #ratingAvg").on("change", function () {
    //var thisRating = $(this).val();
    $.post(
      $("#ajax_url").val(),
      {
        newRating: $(this).val(),
        recipe_id: $("#rating_recipe_id").val(),
        action: "update_recipe_rating",
      },
      function (data) {
        if (!data.error) {
          // update fields
          $("#ratingAvg").val(Math.round(data.score));

          $(".recipe-rating-success").fadeIn("slow", function () {
            // wait and fade away
            $(this).delay(3000).fadeOut("slow");
          });
          // dataLayer.push({
          // 	'score': thisRating,
          // 	'event': 'score',
          // });
          //console.log('score: ' + thisRating );
          //console.log( data );
          //alert('Ääniä: ' + data.votes + ' / KA: ' + data.score);
        } else {
          alert(data.error);
        }
      },
      "json"
    );
  });

  $("#downloadRecipe").on("click", function (e) {
    e.preventDefault();

    var form = $(
      '<form method="post" target="_blank" action="' +
        $("#ajax_url").val() +
        "?action=download_recipe&recipe_id=" +
        $("#recipe_id").val() +
        "&maara=" +
        $("#maaraInput").val() +
        "&annos=" +
        $("#kokoInput").val() +
        '"></form>'
    );
    $("body").append(form);
    form.submit();
    form.remove();
  });

  $(".content-page-header-sidebar .menu-item-has-children > a").on(
    "click",
    function (event) {
      if (!$(this).parent().hasClass("is-open")) {
        event.preventDefault();
        $(this)
          .parent()
          .siblings(".menu-item-has-children")
          .removeClass("is-open")
          .find(".sub-menu")
          .slideUp(400);
        $(this).parent().addClass("is-open");
        $(this).parent().children(".sub-menu").slideDown(400);
      }
      /* Act on the event */
    }
  );

  if ($(window).width() < 1200) {
    $("#navbarToggler .menu-item-has-children > a").on(
      "click",
      function (event) {
        //$('.header-menu-item.has-children > a').on('click', function(event) {
        if (!$(this).parent().hasClass("is-open")) {
          event.preventDefault();
          $(this)
            .parent()
            .siblings(".menu-item-has-children")
            .removeClass("is-open")
            .find(".sub-menu")
            .slideUp(400);
          $(this).parent().addClass("is-open");
          $(this).siblings(".sub-menu").slideDown(400);
        }
        /* Act on the event */
      }
    );
  }

  function checkMainMenuChildren() {
    if ($(window).width() > 1200 && !$("body > header").hasClass("megamenu")) {
      $("#navbarToggler .menu-item .sub-menu").hide();
      $("#navbarToggler .menu-item-has-children").removeClass("is-open");
    }
  }

  function copyToClipboard(elemID) {
    var copyText = document.getElementById(elemID);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  var cruststopclick = false;
  $(".apetit-crust-counter .crust-counter-img").on("click", function () {
    if (cruststopclick) return;
    cruststopclick = true;

    var id = $(this).attr("id");

    if ($(this).hasClass("active")) {
      $("#ct-" + id + ".crust-counter-text").fadeOut(400, function () {
        $(".crust-counter-img:not(.active)").removeClass("hidden");
        $(".crust-counter-img:not(.active) img").delay(400).fadeIn(400);
        cruststopclick = false;
      });
      $(this).removeClass("active");
    } else {
      $(this).addClass("active");
      $(".crust-counter-img:not(.active) img").fadeOut(400, function () {
        $(this).parent().addClass("hidden");
        $("#ct-" + id + ".crust-counter-text")
          .delay(400)
          .fadeIn(400);
        cruststopclick = false;
      });
    }
  });

  $(".algae-button").on("click", function (event) {
    event.preventDefault();
    var current = parseInt(
      $(this).parent().siblings(".algae-counter-count").find("span").text()
    );
    var $counter = $(this)
      .parent()
      .siblings(".algae-counter-count")
      .find("span");

    if ($(this).hasClass("algae-minus")) {
      if (current > 0) {
        current--;
      }
    } else {
      current++;
    }

    $counter.text(current);

    calcAlgae();

    //console.log(current);
  });

  function calcAlgae() {
    var phosph = 0;
    var algae = 0;
    var bucket = 0;

    $(".algae-counter-product").each(function () {
      var count = parseInt($(this).find(".algae-counter-count span").text());
      var factors = $(this).data("factors");
      const [f1, f2, f3] = factors.split(",");

      phosph += count * Number(f1) * Number(f2) * Number(f3) * 1000;
      algae += count * Number(f1) * Number(f2) * Number(f3) * 1000; //phosph;// phosph * 1000;
      bucket += (count * Number(f1) * Number(f2) * Number(f3) * 1000) / 10; //algae / 10;
    });

    $(".algae-results-phosph span").text(Math.round(phosph * 100) / 100);
    $(".algae-results-algae span").text(Math.round(algae * 100) / 100);
    $(".algae-results-buckets span").text(Math.round(bucket * 100) / 100);

    //console.log('calcAlgae :: phosph = ' + phosph + ' / algae = ' + algae + ' / bucket = ' + bucket);
  }

  // function checkFilterForm() {
  // 	if ( $(window).width() > 992 ) {
  // 		$('.filter-form-collapse').addClass('show');
  // 	} else {
  // 		$('.filter-form-collapse').removeClass('show');
  // 	}
  // }

  $(window).on("resize", function () {
    checkMainMenuChildren();
    // checkFilterForm();
  });

  // function appendSearchQuery (searchQuery) {
  // 	$( ".product-items-sq" ).empty();
  // 	$( '.product-items-sq' ).append(
  // 			'<span class="badge badge-success mr-5 py-3 px-2">' + searchQuery + ' </span>'
  // 	);
  // }

  $(window).on("load", function () {
    // Block template masonry blocks
    if ($(".apetit-card-columns").length) {
      var elements = document.getElementsByClassName("apetit-card-columns");
      var n = elements.length;

      for (var i = 0; i < n; i++) {
        // eslint-disable-next-line no-undef
        new Masonry(elements[i], {
          itemSelector: ".apetit-card",
          columnWidth: ".card-sizer",
          gutter: 15,
          percentPosition: true,
          horizontalOrder: true,
        }); // eslint-disable-line
      }

      // var msnry = new Masonry( '.apetit-card-columns', { // eslint-disable-line
      // 	itemSelector: ".apetit-card",
      // 	columnWidth: ".card-sizer",
      // 	gutter: 15,
      // 	percentPosition: true,
      // 	horizontalOrder: true,
      // });
    }
  });

  // Open the modal when the button is clicked
  $(".newsletter-modal-toggle").on("click", function () {
    $("#newsletterModal").modal("show");
  });

  // var minColWidth = 300;
  // var roots;

  // function onLoad() {
  // 	var rootElements = document.getElementsByClassName('apetit-card-columns');
  // 	roots = Array.prototype.map.call(rootElements, function(rootElement) {
  // 		var cellElements = rootElement.getElementsByClassName('apetit-card');

  // 		var cells = Array.prototype.map.call(cellElements, function(cellElement) {
  // 			var style = getComputedStyle(cellElement);
  // 			return {
  // 			'element': cellElement,
  // 			'outerHeight': parseInt(style.marginTop) + cellElement.offsetHeight + parseInt(style.marginBottom),
  // 			};
  // 		});

  // 		return {
  // 			'element': rootElement,
  // 			'noOfColumns': 0,
  // 			'cells': cells,
  // 		};
  // 	});

  // 	// do the first layout
  // 	onResize();
  // }

  // function onResize() {
  // 	for (let root of roots) {

  // 		// only layout when the number of columns has changed
  // 		var newNoOfColumns = Math.floor(root.element.offsetWidth / minColWidth);
  // 		if (newNoOfColumns != root.noOfColumns) {

  // 			// initialize
  // 			root.noOfColumns = newNoOfColumns;
  // 			// eslint-disable-next-line
  // 			var columns = Array.from(new Array(root.noOfColumns)).map( function(column) {
  // 				return {
  // 				'cells': new Array(),
  // 				'outerHeight': 0,
  // 				};
  // 			});

  // 			// divide...
  // 			for (let cell of root.cells) {
  // 				var minOuterHeight = Math.min(...columns.map( function(column) {
  // 					return column.outerHeight;
  // 				}));

  // 				var column = columns.find( function(column) {
  // 					return column.outerHeight == minOuterHeight;
  // 				});

  // 				column.cells.push(cell);
  // 				column.outerHeight += cell.outerHeight;
  // 			}

  // 			// calculate masonry height
  // 			var masonryHeight = Math.max(...columns.map( function(column) {
  // 				return column.outerHeight;
  // 			}));

  // 			// ...and conquer
  // 			var order = 0;
  // 			for (let column of columns) {
  // 				for (let cell of column.cells) {
  // 					cell.element.style.order = order++;
  // 					// set the cell's flex-basis to 0
  // 					cell.element.style.flexBasis = 0;
  // 				}
  // 				// set flex-basis of the last cell to fill the
  // 				// leftover space at the bottom of the column
  // 				// to prevent the first cell of the next column
  // 				// to be rendered at the bottom of this column
  // 				column.cells[column.cells.length - 1].element.style.flexBasis = column.cells[column.cells.length - 1].element.offsetHeight + masonryHeight - column.outerHeight - 1 + 'px';
  // 			}

  // 			// set the masonry height to trigger
  // 			// re-rendering of all cells over columns
  // 			// one pixel more than the tallest column
  // 			root.element.style.maxHeight = masonryHeight + 1 + 'px';

  // 			console.log(columns.map( function(column) {
  // 				return column.outerHeight;
  // 			}));
  // 			console.log(root.element.style.maxHeight);
  // 		}
  // 	}
  // }

  // // subscribe to load and resize events
  // window.addEventListener('load', onLoad);
  // window.addEventListener('resize', onResize);
});
